@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body,
#__next {
  height: 100%;
}

input,
textarea {
  -webkit-tap-highlight-color: transparent;
}

.icon-badge {
  /* @apply h-5 w-5 rounded; */
}

.input {
  @apply py-3 px-3;
  @apply rounded-md;
  @apply w-full;
  @apply bg-white dark:bg-transparent;
  @apply border border-zinc-150 dark:border-zinc-700;
  @apply text-zinc-800 dark:text-zinc-100;
  @apply disabled:text-zinc-300 dark:disabled:text-zinc-700;
  @apply placeholder:text-zinc-300 dark:placeholder:text-zinc-700;
  @apply focus-visible:custom-outline;
}

.z-popup {
  @apply z-30;
}

.z-modal {
  @apply z-40;
}

.z-modal-menu {
  @apply z-50;
}

.form {
  @apply space-y-2;
}

.text-secondary {
  @apply text-zinc-400 dark:text-zinc-500;
}

.p-onboarding {
  @apply text-xl w-2/3 mx-auto;
  @apply text-zinc-400 dark:text-zinc-500;
}

kbd {
  @apply bg-zinc-100 dark:bg-zinc-800 text-zinc-600 dark:text-zinc-400;
  @apply border border-zinc-200 dark:border-zinc-700;
  @apply rounded;
  @apply px-[5px] py-[1px];
  @apply text-lg !important;
}

@layer utilities {
  .custom-outline {
    @apply ring ring-2;
    @apply ring-blue-400/50 dark:ring-blue-400/40;
    @apply border-blue-400/50 dark:border-blue-400/40;
  }
}

.bg-sidebar {
  @apply bg-white dark:bg-zinc-800;
}

.bg-main {
  @apply bg-zinc-50 dark:bg-zinc-900;
}

.bg-gutter {
  background: linear-gradient(
    90deg,
    theme('colors.white') 0%,
    theme('colors.white') 50%,
    theme('colors.zinc.50') 50%,
    theme('colors.zinc.50') 100%
  );
}

.dark .bg-gutter {
  background: linear-gradient(
    90deg,
    theme('colors.zinc.800') 0%,
    theme('colors.zinc.800') 50%,
    theme('colors.zinc.900') 50%,
    theme('colors.zinc.900') 100%
  );
}

